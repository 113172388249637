import { Card, Typography } from "antd";

const { Text, Link, Paragraph } = Typography;

const InfoCard = () => {
  return (

   <Card style={{ marginBottom: 20 }} title="生命教育音樂劇登錄系統操作說明">
      <div style={{ marginBottom: 10 }}>
        <Paragraph>
          <Text strong>
          一般登錄流程
          </Text>
          <br></br>
          <ol>
            <li>
              確實填寫所有欄位
              <ul>
                <li>有 * 號標註的欄位為必填欄位，請務必填寫完整。</li>
              </ul>
            </li>
            <li>
              多場次資料填寫
              <ul>
                <li>若您的演出場次不只一場，請填寫完第一筆資料後，按下 「存檔，新增下一筆」 按鍵。</li>
                <li>系統將保留您已填寫的資料，新增的場次只需進行細部修改即可。</li>
              </ul>
            </li>
            <li>
              檢查與修改已登錄資料
              <ul>
                <li>已完成登錄的場次，請再次檢查內容是否有誤。</li>
                <li>若需更正，點選「編輯」進行更新，編輯後記得「存檔」。</li>
                <li>注意：狀態在「未送出」時，可以重新「編輯」或「刪除」</li>
              </ul>
            </li>
            <li>
              確認完成登錄
              <ul>
                <li>確認無誤後，請在「確定」欄位（框框）打勾，並按下 「送出」。</li>
                <li>注意：務必打勾並送出，才算完成登錄。</li>
              </ul>
            </li>
          </ol>
          <br></br>
          <Text strong>
          Excel表格登錄方式
          </Text>
          <br></br>
          <ol>
            <li>
              下載範例檔案
              <ul>
                <li>請先下載系統提供的範例檔案。</li>
                <li>注意： 手機與電話欄位請選擇 文字格式，避免資料錯誤。</li>
              </ul>
            </li>
            <li>
              依範例格式填寫資料
              <ul>
                <li>請勿更改範例檔案的檔名，可在檔名後加上日期，例如：SampleInput-2022.8.11.xlsx。</li>
              </ul>
            </li>
            <li>
              上傳完成
              <ul>
                <li>填寫完成後，將檔案上傳至系統，即完成資料登錄，免按「送出」。</li>
                <li>注意：以此方式無法重新「編輯」或「刪除」。</li>
              </ul>
            </li>
          </ol>
          <br></br>
          <br></br>
          <Text strong>
            若遇已登錄之演出日期、時間、地點等更動，因攸關團隊保險權益，請務必email至lyc@rkf.org.tw通知相關同仁。
          </Text>
          <br></br>
          <br></br>
          若您有系統操作上的問題與保險相關問題，歡迎來電02-29080280#104林小姐，或email：lyc@rkf.org.tw
          洽詢，謝謝。
        </Paragraph>
      </div>
      <div>
        這裡可下載
        <Link
          href="https://docs.google.com/spreadsheets/d/1E616M_F-920HxaILmwkA2phVHYkQpt32/edit?usp=sharing&ouid=116074758041932205788&rtpof=true&sd=true"
          target="_blank"
        >
          範例 excel 檔
        </Link>
        <Text type="danger">
          (手機與電話欄位記得要選擇文字格式，否則會出錯，上傳正式資料前也請刪除範例資料)
        </Text>
      </div>
    </Card>
  );
};

export default InfoCard;
